.button {
  position: relative;

  display: inline-block;

  width: 30px;
  height: 30px;
  margin: 0;
  padding: 0;

  background-color: transparent;

  border-width: 0;
  outline: none;

  cursor: pointer;

  appearance: none;
  fill: currentColor;
}

.light {
  color: #000;

  opacity: 0.25;
}

.dark {
  color: #fff;

  opacity: 0.4;
}

.isInEpisodes {
  opacity: 0.29;
}
