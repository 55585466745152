/* stylelint-disable declaration-empty-line-before */
@import '../../../styles/vars.css';

.root {
  height: 100vh;

  padding: 25px 16px;

  color: #fff;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.5);

  background: #262626;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  @media $contentWidth {
    padding: 65px 15px;
  }
}

.layout {
  max-width: 650px;
  margin: auto;

  font-size: 18px;
  line-height: 22px;

  @media $contentWidth {
    font-size: 20px;
    line-height: 27px;
  }
}

.layout p {
  margin: 0;
}

.layout h1 {
  margin: 0 0 12px;

  font-weight: 700;

  font-size: 20px;

  font-family: $secondaryFont;
  line-height: 27px;

  @media $contentWidth {
    font-size: 24px;
    line-height: 27px;
  }
}

.layout a {
  color: inherit;
  text-decoration: none;

  box-shadow: inset 0 -1px #b88b58;
}

.layout a:hover {
  color: #b88b58;
}

.layout iframe {
  height: 310px;
  margin: 0 0 20px;

  background: transparent;
  border: 0;

  @media $contentWidth {
    margin-bottom: 0;
  }
}

.layout .title {
  margin: 0 0 15px;

  font-weight: normal;
  font-size: 32px;
  font-family: $secondaryFont;
  line-height: 39px;

  @media $contentWidth {
    margin: 0 0 15px;

    font-size: 36px;
    line-height: 38px;
  }
}
