/* stylelint-disable declaration-empty-line-before */
@import '../../../../styles/vars.css';

.root {
  position: relative;

  display: flex;
  flex-flow: column nowrap;
  flex-grow: 1;

  padding: $gapSmall;

  background-color: #fff;

  border-radius: 8px;
  box-shadow: inset 0 0 0 1px #e7e7e7;

  transition: opacity 0.2s;

  -webkit-tap-highlight-color: transparent;

  @media $landscapeTablet {
    padding: $gapMedium;
  }
}

.root:hover {
  @media $landscapeTablet {
    opacity: 0.8;
  }
}

.content {
  flex-grow: 1;

  margin-bottom: 6px;
}

.footer {
  flex-grow: 0;
  flex-shrink: 0;

  color: #757575;

  line-height: 1;

  fill: $brandColor;
}

.is1to1 {
  @media $landscapeTablet {
    align-items: center;
    padding: 30px 45px 18px;

    text-align: center;
  }
}

.is1to1 .content {
  max-width: 1155px;

  @media $landscapeTablet {
    margin-bottom: 20px;
  }
}

.is1to2,
.is1to3,
.is1to4 {
  align-items: flex-start;
  justify-content: flex-start;
}
