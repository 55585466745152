/* stylelint-disable declaration-empty-line-before */
@import '../../../../styles/vars.css';

.root {
  display: flex;
  flex-flow: row nowrap;

  width: 100%;
  max-width: $layoutWidth;
  margin: 0 auto $gapMedium;
}

.root:last-child {
  margin-bottom: 0;
}

.isSuperblock {
  max-width: $layoutWidth;
  padding-right: 0;
  padding-bottom: 0;
  padding-left: 0;
}

.block {
  display: inline-flex;
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 1;

  width: 100%;

  vertical-align: top;
}

.block + .block {
  margin-left: 15px;
}

.block.unknown {
  background-color: #a0a0a0;
}

.is1to1 {
  width: 100%;
}

.is1to2 {
  width: 50%;
}

.is1to3 {
  width: 33.33333%;
}

.is1to4 {
  width: 25%;
}

.isNews.is1to3:first-child {
  width: 51.5%;
}

.is1to1.isPodcast,
.is1to2.isPodcast,
.is1to1.isEpisode,
.is1to2.isEpisode,
.is1to2.isBrief_subscription,
.is1to3.isBrief_subscription {
  display: flex;
  flex-flow: nowrap row;
}

.is1to1.isPodcast::before,
.is1to2.isPodcast::before,
.is1to1.isEpisode::before,
.is1to2.isEpisode::before,
.is1to2.isBrief_subscription::before,
.is1to3.isBrief_subscription::before {
  display: table;

  width: 0;
  height: 0;

  content: '';
}

.is1to1.isPodcast::before,
.is1to1.isEpisode::before {
  padding-bottom: 33%;
}

.is1to2.isPodcast::before,
.is1to2.isEpisode::before,
.is1to2.isBrief_subscription::before,
.is1to3.isBrief_subscription::before {
  padding-bottom: 66.66%;
}

.block.isSuperblock {
  margin-bottom: 0;

  @media $mobile {
    padding-bottom: 0;
  }
}

.isWide {
  display: block;
}

.isWide .currentMaterial::before,
.isWide .currentMaterial article {
  display: none;
}

.isWide .currentMaterial {
  display: block;
}

.isWide:first-child .currentMaterial {
  margin-bottom: -15px;
}

.unknown {
  display: none;
}
