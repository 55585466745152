/* stylelint-disable declaration-empty-line-before */
@import '../../../../styles/vars.css';

.root {
  position: relative;
  z-index: 1;

  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: hidden;

  color: #000;

  border-radius: 8px;

  cursor: pointer;

  transition: opacity 0.2s;

  -webkit-tap-highlight-color: transparent;
}

.root a {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 100%;
}

.title,
.secondTitle {
  position: absolute;
  z-index: -1;

  color: transparent;

  text-align: center;
}

.secondTitle {
  top: 51%;
}

.root:hover {
  @media $landscapeTablet {
    opacity: 0.95;
  }
}

.root img {
  display: block;

  width: 100%;
  height: 100%;

  object-fit: cover;
  object-position: center;
}

.richPromoImage {
  position: relative;
  top: 0;
  left: 0;
  z-index: 2;

  transition: opacity 0.25s ease-in;
}

.richPromoHover {
  position: absolute;
  top: 0;
  left: 0;
}

.rootWithHover:hover .richPromoImage {
  opacity: 0;
}

.richPromoHover {
  z-index: 1;
}

.is1to1,
.is1to1 img {
  height: auto;
}

.is1to2,
.is1to2 img {
  min-height: 410px;
}

.is1to3 {
  min-height: 436px;
}

.is1to4 {
  min-height: 420px;
}

.isMobile {
  height: auto;
}

.imageContainer {
  position: relative;

  width: 100%;
  height: 100%;
}
