@import '../../../styles/vars.css';

.root {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-start;

  min-width: 160px;
  height: 100%;
}

.button {
  position: relative;

  display: block;

  height: 100%;
  margin: 0;
  padding: 0 1px 0 0;

  color: #000;
  font-weight: normal;
  font-size: 16px;

  font-family: $secondaryFont;
  line-height: 1;

  background-color: transparent;
  border-width: 0;
  outline: none;

  cursor: pointer;

  appearance: none;
  pointer-events: none;
}

.default {
  pointer-events: initial;
}

.button::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  content: '';
}

.button:hover {
  opacity: 0.6;
}

.isDark,
.dark {
  color: #fff;
}

.isDark:hover,
.dark:hover {
  opacity: 0.6;
}

.isDark svg,
.dark svg {
  color: #262626;

  fill: #fff;
}

.isDark.bookmark svg,
.dark.bookmark svg {
  color: #fff;

  fill: transparent;
}

.isDark.bookmark.isActive svg,
.dark.bookmark.isActive svg {
  fill: currentColor;
}

.isDark::before,
.dark::before {
  border-left-color: rgba(255, 255, 255, 0.2);
}

.button [data-theme='dark'] {
  visibility: hidden;
}

.isDark [data-theme='dark'],
.dark [data-theme='dark'] {
  visibility: visible;
}

.isDark [data-theme='light'],
.dark [data-theme='light'] {
  visibility: hidden;
}

.icon {
  display: inline-block;

  width: 24px;
  margin-top: -2px;

  vertical-align: middle;
}

.text {
  display: inline-block;

  vertical-align: middle;
}

.isActive {
  fill: currentColor;
}

.isLoading .icon svg {
  width: 13px;
  height: 13px;
  margin-top: 4px;
}

.isLoading {
  color: rgba(0, 0, 0, 0.2);
}

.tip {
  margin-left: 7px;

  color: #a6a6a6;

  cursor: default;

  pointer-events: initial;
}

.tip:hover {
  opacity: 1;
}

.hiddenTextArea {
  position: absolute;
  top: 0;
  z-index: -1;

  opacity: 0;
}
