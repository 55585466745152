/* stylelint-disable declaration-empty-line-before */
/* stylelint-disable declaration-colon-newline-after */
@import '../../../../styles/vars.css';

.root {
  position: relative;
  z-index: 1;

  display: flex;
  flex-flow: column nowrap;
  flex-grow: 1;

  width: 100%;

  background-color: #fff;

  border-radius: 8px;
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.1);

  transition: opacity 0.2s;
}

.root:hover {
  @media $landscapeTablet {
    z-index: 10;

    opacity: 0.95;
  }
}

.container {
  display: flex;
  flex-flow: column nowrap;
  flex-grow: 1;
}

.imageWrap {
  position: relative;

  width: 100%;
  min-height: 236px;

  @media $landscapeTablet {
    min-height: 0;

    border-radius: 8px;
  }
}

.image {
  position: relative;
  right: 0;
  left: 0;
  z-index: $zIndex-3;

  display: flex;

  width: 160px;
  height: 160px;
  margin: 0 auto;
  overflow: hidden;

  border-radius: 2px;
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.15);

  transition: opacity 350ms ease;

  @media $mobile {
    width: 180px;
    height: 180px;
  }
}

.picture {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
}

.picture img {
  display: block;

  width: 100%;
  height: 100%;
}

.tag {
  display: flex;

  margin-bottom: 6px;

  color: $brandColor;

  opacity: 0.6;
}

.is1to1 .tag,
.is1to2 .tag {
  color: rgba(255, 255, 255, 0.6);
}

.contentWrap {
  z-index: 1;

  display: flex;
  flex-direction: column;
  flex-grow: 1;

  width: 100%;
  padding: 0 15px;

  @media $landscapeTablet {
    padding: 0 calc(15rem / $fontSize);
  }
}

.is1to2 .contentWrap {
  @media $landscapeTablet {
    justify-content: flex-end;

    color: #fff;
  }
}

.is1to1 .container {
  @media $landscapeTablet {
    flex-flow: row nowrap;
    align-items: center;
  }
}

.is1to1 .footer {
  @media $landscapeTablet {
    margin-top: -12px;

    /* color: rgba(255, 255, 255, 0.5); */

    fill: currentColor;
  }
}

.is1to2 .footer {
  @media $landscapeTablet {
    /* color: rgba(255, 255, 255, 0.7); */

    fill: currentColor;
  }
}

.content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  padding: $gapSmall 0;

  @media $landscapeTablet {
    justify-content: flex-end;

    padding: 15px 0;
  }
}

.title {
  max-width: 770px;
  margin-bottom: 12px;

  @media $mobile {
    width: 100%;
    min-width: 0; /* FIX for IE10  */
    margin-bottom: 20px;
  }
}

.footer {
  margin-top: auto;

  color: #fff;

  line-height: 14px;

  opacity: 0.7;

  fill: currentColor;
}

.footer.dark {
  color: #000;

  opacity: 0.5;
}

.is1to1 .title,
.is1to1 .tag,
.is1to2 .title,
.is1to2 .tag {
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.5);
}

.is1to1.dark .title,
.is1to1.dark .tag,
.is1to2.dark .title,
.is1to2.dark .tag {
  text-shadow: none;
}

.is1to3 .tag,
.is1to4 .tag {
  opacity: 1;
}

.is1to3 .footer,
.is1to4 .footer {
  color: #757575;

  opacity: 1;
}

.is1to1 .title {
  margin-bottom: 20px;
}

.is1to2 .imageWrap {
  @media $landscapeTablet {
    position: static;

    flex-shrink: 0;

    padding-top: 40px;
    padding-bottom: 0;
  }
}

.is1to2 .content {
  flex-shrink: 0;

  @media $landscapeTablet {
    flex-grow: 0;
    justify-content: flex-end;

    padding-top: 0;
  }
}

.is1to2 .image,
.is1to3 .image {
  position: relative;

  box-shadow: 0 7px 14px 0 rgba(0, 0, 0, 0.34);

  @media $landscapeTablet {
    width: calc(140rem / $fontSize);
    height: calc(140rem / $fontSize);
  }
}

.is1to3 .image,
.is1to4 .image {
  position: absolute;
  top: 50%;

  transform: translateY(-50%);
}

.is1to3 .imageWrap,
.is1to4 .imageWrap {
  overflow: hidden;

  border-radius: 8px 8px 0 0;

  @media $landscapeTablet {
    min-height: auto;
  }
}

.is1to3 .imageWrap::before,
.is1to4 .imageWrap::before {
  display: block;

  width: 100%;
  padding-bottom: 57.142857142857146%;

  content: '';

  @media $landscapeTablet {
    padding-bottom: 66.66%;
  }
}

.is1to4 .image {
  box-shadow: 0 7px 14px 0 rgba(0, 0, 0, 0.34);

  @media $landscapeTablet {
    width: 140px;
    height: 140px;
  }
}

.cover {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  background-color: #1a1a1a;

  border-radius: 8px 8px 0 0;
}

.is1to1 .cover,
.is1to2 .cover {
  border-radius: 8px;
}

.is1to1 {
  @media $landscapeTablet {
    min-height: 410px;
  }
}

.is1to1 .content {
  @media $landscapeTablet {
    max-width: 585px;
    padding: 0;
  }
}

.is1to1 .imageWrap {
  @media $landscapeTablet {
    position: static;

    flex-shrink: 0;

    width: 39.2771084337%;

    pointer-events: none;
  }
}

.is1to1 .image {
  position: relative;

  width: 250px;
  height: 250px;
  margin-right: 15px;
  margin-left: auto;

  box-shadow: 0 7px 14px rgba(0, 0, 0, 0.7);
}

.is1to1 .contentWrap {
  @media $landscapeTablet {
    flex-grow: 0;

    width: 60%;
    padding: 20px 0 0 15px;

    color: #fff;
  }
}

.is1to1,
.is1to2,
.is1to3 .imageWrap,
.is1to4 .imageWrap {
  background-color: #fff;
}

.audio {
  position: relative;
  z-index: 10;
}

.audioPanel {
  display: flex;
  justify-content: space-between;
  padding-bottom: 12px;

  @media $landscapeTablet {
    gap: 10px;
    padding-bottom: 28px;
  }
}

.mainPanel {
  display: flex;
  gap: 10px;
  align-items: center;
}

.button {
  position: relative;

  display: inline-block;

  width: 30px;
  height: 30px;
  margin: 0;
  padding: 0;

  color: #969696;

  background-color: transparent;
  border-width: 0;
  outline: none;

  cursor: pointer;

  appearance: none;
  fill: currentColor;
}

.buttonPlay {
  width: 36px;
  height: 36px;

  border-radius: 50%;
  outline: none;
  box-shadow: inset 0 0 0 2px #b3b3b3;
}

.buttonPlayLight {
  box-shadow: inset 0 0 0 2px rgba(255, 255, 255, 0.3);
}

.buttonPlayDark {
  box-shadow: inset 0 0 0 2px rgba(0, 0, 0, 0.25);
}

.play {
  position: relative;
  z-index: 1;

  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;

  width: 36px;
  height: 36px;
  margin-right: 6px;
  margin-left: auto;
}

.metaContainer {
  font-size: 12px;

  font-family: $secondaryFont;
  line-height: 15px;

  transform: translateZ(0);

  opacity: 0.5;

  fill: inherit;
}

.gradient {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;

  width: 93.9759036145%;

  border-radius: 8px;
}

.ambiance {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: -1;

  width: 100%;
}

.ambiance img {
  display: block;

  width: auto;
  height: 100%;

  border-radius: 8px 0 0 8px;
}

.withAmbiance::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;

  border-radius: 8px;
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.1);

  content: '';
}

.withAmbiance .cover {
  @media $landscapeTablet {
    display: none;
  }
}

.withAmbiance .imageWrap {
  @media $landscapeTablet {
    width: 100%;
    min-height: auto;
  }
}

.withAmbiance .image {
  @media $landscapeTablet {
    width: 65px;
    height: 65px;
    margin: 0;
  }
}

.withAmbiance .container {
  @media $landscapeTablet {
    flex-flow: column nowrap;
    justify-content: center;

    width: 100%;
    max-width: 48.1540930979%;
    margin-left: auto;
    padding-top: 35px;
    padding-bottom: 15px;
  }
}

.withAmbiance .content {
  @media $landscapeTablet {
    max-width: none;
  }
}

.withAmbiance .contentWrap {
  @media $landscapeTablet {
    width: 100%;
    padding: 22px 14px 20px 0;
  }
}

.withAmbiance .title {
  margin-bottom: 8px;
}

.withAmbiance.is1to1 .title {
  @media $mobile {
    margin-bottom: 28px;
  }
}

.bookmark {
  position: absolute;
  right: 16px;
  bottom: 8px;
  z-index: 10;

  @media $landscapeTablet {
    right: 10px;
    bottom: 8px;

    transform: scale(0);
  }
}

.is1to1 .bookmark {
  @media $landscapeTablet {
    right: 14px;
  }
}

.root:hover .bookmark {
  @media $landscapeTablet {
    animation: show 350ms ease both;
  }
}

.contentWrap.dark {
  color: #000;
}

@keyframes show {
  0% {
    transform: scale(0);
  }

  60% {
    transform: scale(1.2);
  }

  100% {
    transform: scale(1);
  }
}
