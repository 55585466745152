/* stylelint-disable declaration-empty-line-before */
@import '../../../styles/vars.css';

.root {
  position: relative;
  z-index: auto;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  padding: 0 20px 20px;

  color: #999;

  font-family: $secondaryFont;

  text-align: center;

  @media $landscapeTablet {
    justify-content: flex-start;

    padding: 16px 0 15px;

    text-align: left;
  }
}

.nav a,
.push {
  color: #fff;

  transition: color 150ms ease-out;
}

.nav a:hover,
.howto a:hover,
.push:hover {
  color: rgba(255, 255, 255, 0.5);
}

.push {
  display: block;

  padding: 5px 0 2px;

  background-color: transparent;

  border: 0;

  cursor: pointer;

  appearance: none;

  @media $landscapeTablet {
    padding: 7px 0;
  }
}

.push.disabled {
  opacity: 0.5;

  pointer-events: none;
}

.container {
  width: 100%;
  max-width: $layoutWidth;

  @media $landscapeTablet {
    padding: 0 calc(45rem / $fontSize);
  }
}

.tip {
  display: none;

  @media $landscapeTablet {
    display: block;

    padding-bottom: 12px;
  }
}

.groupTitle {
  margin-bottom: 7px;

  color: rgba(255, 255, 255, 0.5);
  font-weight: 600;

  font-size: 12px;
  line-height: 16px;

  letter-spacing: 1px;
  text-transform: uppercase;
}

.groupList {
  margin: 0;
  padding: 0;

  font-size: 14px;
  line-height: 18px;

  list-style: none;
}

.aboutEn .groupList,
.platforms .groupList,
.apps .groupList {
  @media $landscapeTabletMax {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
  }
}

.aboutEn li,
.platforms li,
.apps li {
  @media $landscapeTabletMax {
    padding-right: 12px;
    padding-left: 12px;
  }
}

.expanded .platforms li:last-child {
  @media $landscapeTabletMax {
    width: 100%;
  }
}

.groupList a {
  display: block;

  padding: 7px 0;
}

.nav {
  display: flex;
  flex-flow: column wrap;

  max-width: 215px;
  margin: 0 auto 20px;

  @media $landscapeTablet {
    align-content: space-between;

    width: 50%;
    max-width: none;
    margin: 0;
    padding: 0 15px;
  }
}

.group {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;

  width: 100%;
  margin-bottom: 20px;

  @media $landscapeTablet {
    display: block;

    width: 160px;
    margin-bottom: 41px;
    margin-left: auto;
  }
}

.group:first-child {
  margin-left: 0;
}

.expanded .nav {
  @media $landscapeTablet {
    height: 375px;
    margin-bottom: 16px;
  }
}

.short .nav {
  @media $landscapeTablet {
    height: 276px;
    margin-bottom: 16px;
  }
}

.short .copyright {
  @media $landscapeTablet {
    margin-left: auto;
  }
}

.short .podcasts .groupTitle {
  position: relative;
}

.short .podcasts .groupTitle::after {
  position: absolute;
  bottom: 50%;
  left: 100%;

  width: 17px;
  height: 28px;

  background-image: url("data:image/svg+xml,%3Csvg fill='none' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 17 28'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M6.301 7.29s-.625-1.142-.43-2.37c.195-1.228 1.277-1.948 2.424-1.672 1.147.275 1.645 1.037 1.71 2.074.065 1.038-.175 1.411-.517 1.976-.256.425-1.085.723-1.085.723s.628.298 1.083.531c.454.233 2.056.732 3.571-.58 1.516-1.313 1.555-2.556 1.645-3.064.13-.727 1.559-.66 1.256.8-.303 1.46-1.126 3.153-2.273 3.872-1.147.72-2.792 1.038-4.437.847 0 0-.65 4.15.26 4.277.909.127 1.634-1.072 2.424-1.207s.844.72.714 1.673c-.13.952-.087 2.223-.087 2.223s.542-.678.866-.614c.325.063.433 1.037-.13 1.588-.562.55-1.58 1.609-1.84 1.63-.26.021-.757-.762-.692-1.291.065-.53.158-2.16.158-2.16s-2.063 1.186-3.708.826c0 0-.47 2.604-1.371 3.599-.902.995-3.067 3.091-3.067 3.091s.174.804.347 1.143c.173.339.627 1.355.368 1.673-.26.317-1.32.021-1.732-.953-.411-.974-.758-1.863-.758-2.16 0-.296-.043-.677.758-.973.8-.297 2.381-2.118 2.576-2.732.195-.613.628-3.578.628-3.578s-.412-.38-.477-1.185.087-6.173.087-6.173-3.269-.941-3.398-3.355c-.13-2.413.094-3.994.39-4.467.237-.381 1.19-.677 1.146.91-.043 1.588-.085 2.823.164 3.515.281.783 1.049 1.355 2.153 1.61 1.104.253 1.274.216 1.274.216v-.264Z' fill='%2361FF00' stroke='%23000' stroke-width='.305'/%3E%3Cpath d='M4.931 16.438s.571.512 1.193.317c0 0 .239.467 1.217.646M10.93 16.54s.52-.306.725-.61M12.569 17.078l-.028 1.01M2.43 23.515s-.335-.836-.954-.601' stroke='%23000' stroke-width='.305' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
  transform: translateY(50%) translateX(8px);

  content: '';

  @media $landscapeTablet {
    right: 100%;
    bottom: 100%;
    left: auto;

    transform: none;
  }
}

.panel {
  padding-top: 20px;

  font-size: 14px;
  line-height: 1;

  border-top: 1px solid #333;

  @media $landscapeTablet {
    display: flex;
    flex-flow: row nowrap;

    margin: 0 15px;

    line-height: 20px;
  }
}

.howto {
  margin-bottom: 14px;

  @media $landscapeTablet {
    margin-right: auto;
    margin-bottom: 0;
  }
}

.expanded .copyright::before {
  @media $landscapeTablet {
    padding: 0 5px;

    content: '·';
  }
}

.main {
  position: relative;

  display: flex;
  flex-flow: column nowrap;

  @media $landscapeTablet {
    flex-flow: row nowrap;
  }
}

.hidden {
  position: absolute;

  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;

  overflow: hidden;

  clip: rect(0 0 0 0);
}

.form {
  order: -1;

  margin-bottom: 80px;

  @media $landscapeTablet {
    order: initial;

    width: 50%;
    margin-bottom: 0;
  }
}
