/* stylelint-disable declaration-empty-line-before */
@import '../../../../styles/vars.css';

.root {
  position: relative;
  z-index: $zIndex-1;

  flex-grow: 1;
}

.head {
  position: relative;

  padding: 40px 20px 19px;

  background-color: #fff;

  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 8px 8px 0 0;

  @media $mobile {
    padding: 60px 20px 19px;
  }
}

.body {
  padding: 10px;

  background-color: #f2f2f2;

  border-radius: 0 0 8px 8px;

  @media $mobile {
    padding: 20px 0 40px;
  }
}

.container {
  width: 100%;
  max-width: 650px;
  margin: 0 auto;
}

.image {
  margin-bottom: 30px;
}

.panel {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;

  max-width: 650px;
  margin: 0 auto 10px;
  padding: 12px 14px 12px 12px;

  background-color: #fff;

  border-radius: 8px;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);

  @media $mobile {
    margin-bottom: 20px;
    padding: 20px;
  }
}

.panel.isWithSeasons {
  margin-bottom: 0;

  border-radius: 8px 8px 0 0;
}

.subscribe {
  width: 100%;

  font-size: 16px;
  line-height: 22px;

  @media $mobile {
    font-size: 20px;
    line-height: 28px;
  }
}

.arrow {
  display: inline-block;

  width: 24px;
  height: 14px;
  margin-left: 5px;

  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='14' viewBox='0 0 24 14' fill='none'%3E %3Cpath d='M21 2.5L12 11.5L3 2.5' stroke='%23B3B3B3' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E %3C/svg%3E");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100% auto;

  @media $mobile {
    width: 16px;
    height: 10px;
    margin-left: 8px;

    background-image: url("data:image/svg+xml,%3Csvg width='16' height='10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m14 1.739-6 6.26-6-6.26' stroke='%23000' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
  }
}
