@import '../../../styles/vars.css';

.root {
  position: sticky;
  z-index: 120;
  bottom: 0;

  display: none;
  align-items: center;
  flex-flow: column nowrap;

  margin-top: -20px;
  margin-bottom: 40px;

  font-family: $secondaryFont;

  @media $landscapeTablet {
    margin-top: 0;
    margin-bottom: -40px;
  }
}

.container {
  width: 100%;
  max-width: $layoutWidth;
  padding-top: 20px;

  @media $landscapeTablet {
    padding: 20px;
  }
}

.root.isRendered {
  display: flex;
  visibility: hidden;
}

.isVisible.isRendered {
  visibility: visible;

  animation: slideIn 1s cubic-bezier(1, -0.4, 0.35, 0.95);
}

.panel {
  position: relative;
  z-index: 10;

  display: flex;
  flex-flow: column nowrap;

  max-height: 100svh;
  padding-bottom: 20px;

  transform: translateY(20px);

  border-radius: 20px;
  background-image: linear-gradient(126.42deg, #c61309 50.13%, #1f0606 169.73%);

  @media $landscapeTablet {
    padding-bottom: 0;
    border-radius: 40px;
    transform: translateY(0);

    height: 439px;
  }
}

.panel::before {
  position: absolute;

  content: '';

  pointer-events: none;

  background-image: url('./assets/PopoverBgMobile.svg');
  background-repeat: no-repeat;
  background-position: center bottom;
  background-size: contain;

  inset: 0;

  @media $landscapeTablet {
    border-radius: 40px;
    background-image: url('./assets/PopoverBgDesktop.svg');
    background-size: cover;
  }
}

.panel::after {
  position: absolute;

  content: '';
  pointer-events: none;

  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: inherit;

  inset: 0;
}

.dismiss {
  position: absolute;
  z-index: 20;
  top: -16px;
  right: 50%;

  display: flex;
  align-items: center;
  justify-content: center;

  width: 32px;
  height: 32px;
  padding: 0;

  cursor: pointer;
  transform: translateX(50%);

  color: #fff;
  border-width: 0;
  border-radius: 50%;
  background-color: transparent;
  background-color: rgba(205, 2, 2, 1);
  box-shadow: none;

  appearance: none;
  fill: transparent;

  @media $landscapeTablet {
    top: 30px;
    right: 30px;

    width: 42px;
    height: 42px;

    transform: none;

    background-color: rgba(255, 252, 190, 0.1);
  }
}

.dismiss:hover {
  opacity: 0.8;
}

.dismiss svg {
  display: block;

  width: 18px;
}

.simple.isRendered {
  position: relative;

  visibility: visible;

  animation: none;
}

@keyframes slideIn {
  from {
    transform: translateY(100%);
  }

  to {
    transform: translateY(0);
  }
}
