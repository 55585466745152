@import '../../../styles/vars.css';

.root {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 501;

  display: flex;

  width: 100%;
  height: 100%;
  overflow: auto;

  background-color: rgba(0, 0, 0, 0.9);
  visibility: hidden;

  opacity: 0;

  @media $mobile {
    z-index: 301;

    flex-direction: column;

    align-items: center;

    padding-bottom: 12px;

    transition: opacity 250ms ease, visibility 250ms ease;
  }
}

.root p {
  margin: 0;
}

.root p mark {
  white-space: normal;
}

.active {
  visibility: visible;

  opacity: 1;
}

.active .content {
  transform: scale(1) translateY(0);

  opacity: 1;
}

.header {
  margin-bottom: 16px;

  @media $mobile {
    margin-bottom: 20px;

    text-align: center;
  }
}

.header p {
  margin: 8px 0 0;

  font-size: 14px;

  font-family: $secondaryFont;
  line-height: 18px;

  @media $mobile {
    margin: 10px 0 0;

    font-size: 20px;
    line-height: 28px;
  }
}

.header h3 {
  font-weight: 700;
  font-size: 20px;
  font-family: $secondaryFont;
  line-height: 24px;

  @media $mobile {
    margin-bottom: 9px;

    font-size: 28px;
    line-height: 34px;
  }
}

.isPostAuth h3,
.isAuth h3 {
  @media $mobileMax {
    margin-bottom: 10px;
  }
}

.isInTextError h3 {
  @media $mobile {
    margin-bottom: 17px;
  }
}

.content {
  position: relative;

  display: flex;
  flex-direction: column;
  align-items: stretch;

  width: 100%;
  margin: auto;

  transform: scale(0.5) translateY(100px);

  opacity: 0;

  @media $mobile {
    transition: opacity 250ms ease, transform 250ms ease;
  }
}

.content p a {
  color: inherit;

  box-shadow: inset 0 -1px #b88b58;
}

.controls {
  max-width: 348px;
  margin: 20px auto 0;

  @media $mobile {
    margin-top: 38px;
  }
}

.footer {
  padding: 20px $gapMedium;

  text-align: center;
}

.dismiss {
  position: absolute;

  display: none;

  padding: 0;

  background-color: transparent;

  border-width: 0;
  box-shadow: none;

  cursor: pointer;

  appearance: none;

  @media $mobile {
    top: 22px;
    right: 22px;

    display: block;

    color: #aaa;
  }
}

.dismiss svg {
  display: block;

  width: 21px;
  height: 21px;
}

.host {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: $zIndex-3;

  display: flex;
  flex-direction: column;
  overflow-y: auto;

  background: rgba(0, 0, 0, 0.5);

  opacity: 0;

  transition: opacity 0.15s ease-out;

  pointer-events: none;
}

.host.isActive {
  opacity: 1;

  pointer-events: auto;
}

.container {
  flex-shrink: 0;

  width: 100%;
  padding: 50px 12px 12px;

  font-family: $secondaryFont;

  background: #f7f7f7;

  border-radius: 10px 10px 0 0;

  @media $mobile {
    padding: 70px 20px 20px;

    border-width: 0;
    border-radius: 8px;
    box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.1);
  }
}

.submit {
  margin-top: 10px;

  text-align: center;
}

.label {
  display: block;

  margin-bottom: 5px;

  font-weight: 600;

  font-size: 13px;
  line-height: 16px;
  letter-spacing: 0.2px;

  text-align: left;

  @media $mobile {
    padding-left: 10px;

    font-size: 14px;
    line-height: 17px;
  }
}

.subtitle {
  color: #000;
  font-size: 16px;

  font-family: $secondaryFont;
  line-height: 22px;
  text-align: center;

  @media $mobile {
    font-size: 18px;
    line-height: 26px;
  }
}

.loading {
  padding: 6px 0;

  text-align: center;
}

.auth {
  max-width: 335px;
  min-height: 142px;
  margin: 0 auto;
}

.separator {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;

  max-width: 335px;
  margin: 22px auto 35px;

  color: #a8a8a8;

  font-size: 12px;
  line-height: 15px;

  cursor: default;

  user-select: none;
}

.separator::before,
.separator::after {
  flex-grow: 1;

  border-top: 1px solid #d8d8d8;

  content: '';
}

.separator::after {
  margin-left: 8px;
}

.separator::before {
  margin-right: 8px;
}

.isSearch.root {
  position: static;

  background-color: transparent;

  opacity: 1;
}

.isSearch .content {
  margin-top: 0;

  transform: none;
}

.isSearch .dismiss {
  display: none;
}

.isTextError .content,
.isIdea .content,
.isSendMessage .content,
.isDeleteUser .content,
.isReaction .content,
.isPostAuth .content {
  max-width: 650px;
  margin-bottom: 0;

  @media $mobile {
    margin-bottom: auto;
  }
}

.isIdea .root,
.isSendMessage .root,
.isTextError .root {
  z-index: $zIndex-4;

  padding-right: 10px;
  padding-left: 10px;
}

.isDeleteUser .container {
  @media $mobile {
    padding: 112px 90px 120px;
  }
}

.dismissOverlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  display: none;

  content: '';
}

.background {
  display: none;
  overflow: hidden;
}

.background svg {
  top: 0;
  left: 0;
  z-index: -1;

  color: #f7f7f7;

  border-radius: 8px 8px 0 0;
}

.background svg,
.background rect {
  position: absolute;

  width: 100%;
  height: 100%;
}

/* MODALS */
.isAuth,
.isPostAuth {
  position: static;

  @media $mobile {
    position: fixed;
  }
}

.isAuth .form {
  max-width: 335px;
  min-height: 107px;
  margin: 0 auto;
}

.isAuth .content {
  max-width: 650px;
}

.isPostAuth .header {
  max-width: 430px;
  margin-right: auto;
  margin-left: auto;
}

.isReaction .container,
.isPostAuth .container,
.isAuth .container {
  height: calc(100vh - 71px); /* vh - header */

  @media $mobile {
    height: auto;
  }
}

.isAuth .dismiss,
.isPostAuth .dismiss,
.isReaction .dismiss {
  @media $mobileMax {
    top: 15px;
    right: 15px;

    display: block;

    color: #aaa;
  }
}

.isAuth .container {
  margin: 0 auto;

  text-align: center;

  background-color: #f7f7f7;

  @media $mobile {
    padding: 70px 110px 28px;
  }
}

.isPostAuth .container {
  padding-right: 20px;
  padding-left: 20px;

  text-align: center;

  background-color: #f7f7f7;
}

.isIdea .dismiss,
.isSendMessage .dismiss,
.isInTextError .dismiss,
.isDeleteUser .dismiss {
  @media $mobileMax {
    top: -23px;
    left: 50%;

    display: block;

    width: 50px;
    height: 36px;
    padding: 0;

    background-color: transparent;
    background-image: url("data:image/svg+xml,%3Csvg width='17' height='7' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M.445 1.832l6.946 4.63a2 2 0 0 0 2.218 0l6.946-4.63a1 1 0 1 0-1.11-1.664L8.5 4.798 1.555.168a1 1 0 1 0-1.11 1.664z' fill='%23F7F7F7' fill-rule='nonzero'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: center;

    transform: translateX(-50%);

    opacity: 0.4;
  }
}

.isIdea .dismiss svg,
.isSendMessage .dismiss svg,
.isInTextError .dismiss svg,
.isDeleteUser .dismiss svg {
  @media $mobileMax {
    display: none;
  }
}

.isInTextError .container,
.isIdea .container,
.isSendMessage .container,
.isDeleteUser .container {
  @media $mobileMax {
    padding-top: 22px;

    text-align: center;

    background-color: transparent;
  }
}

.isInTextError .dismissOverlay,
.isInTextError .background,
.isIdea .dismissOverlay,
.isIdea .background,
.isSendMessage .dismissOverlay,
.isSendMessage .background,
.isDeleteUser .dismissOverlay,
.isDeleteUser .background {
  @media $mobileMax {
    display: block;
  }
}

.email {
  margin-bottom: 8px;
}

.formField {
  min-height: 42px;
  padding: 13px 12px 11px;

  background-color: #000;

  border-style: none;
  border-radius: 8px;

  will-change: opacity, transform;
}

.formField.hasError,
.field.hasError {
  box-shadow: inset 0 0 0 1px #f95a5a;
}

.message {
  margin: 0 auto;

  font-size: 16px;
  line-height: 22px;

  text-align: center;

  @media $mobile {
    font-size: 18px;
    line-height: 26px;
  }
}

.link {
  padding: 0;

  color: #757575;

  font-size: 12px;
  line-height: 15px;
  text-decoration: underline;

  background-color: transparent;
  border-width: 0;

  cursor: pointer;

  appearance: none;
}

.link:hover {
  color: $brandColor;
}

.error {
  display: block;

  width: 218px;
  margin: 15px auto -30px;

  color: #f95a5a;

  font-size: 13px;
  line-height: 22px;

  text-align: center;
}

.error a {
  color: inherit;
  text-decoration: underline;
}
