@import '../../../../styles/vars.css';

.root {
  position: relative;

  width: 100%;
  margin: 21px auto 0;

  @media $landscapeTablet {
    margin-top: 25px;
  }
}

.rootEn {
  position: relative;

  width: 100%;
  margin: auto 0 0;
}

.form {
  position: relative;

  display: flex;
  flex-flow: row nowrap;

  width: 100%;

  background: rgba(255, 255, 255, 0.1);

  border-radius: 8px 28px 28px 8px;
}

.successedForm {
  visibility: hidden;
}

.field {
  width: 100%;
}

.field label {
  position: absolute;
  z-index: 1;

  visibility: hidden;

  opacity: 0;
}

.invalid .input {
  box-shadow: inset 0 0 0 1px #f75a57;
}

.input {
  display: block;

  width: 100%;
  height: 48px;
  padding: 0;

  color: #fff;
  font-weight: 500;
  font-size: 16px;

  font-family: $secondaryFont;
  text-indent: 16px;

  background: transparent;
  border-width: 0;
  border-radius: 8px;
  outline: none;

  transition: border-color 0.1s ease-in;

  appearance: none;

  @media $landscapeTablet {
    height: 56px;

    font-size: 14px;

    text-indent: 16px;
  }
}

.input:focus {
  box-shadow: inset 0 0 0 1px #fff;
}

.input::placeholder {
  color: rgba(255, 255, 255, 0.4);

  opacity: 1;
}

.message {
  position: absolute;
  top: 100%;
  right: 0;
  left: 0;

  color: #f2614e;
  font-size: 14px;

  font-family: $secondaryFont;
  line-height: 18px;

  transform: translateY(6px);

  @media $landscapeTablet {
    line-height: 1;

    transform: translateY(8px);
  }
}

.message a {
  text-decoration: underline;
}

.button {
  flex-shrink: 0;

  width: 72px;
  height: 48px;
  padding: 1px 1px 0 0;

  color: #fff;
  font-weight: 600;
  font-size: 20px;

  font-family: $secondaryFont;
  line-height: 28px;
  text-transform: uppercase;

  background-color: #545454;
  border-width: 0;
  border-radius: 50px;
  box-shadow: none;

  cursor: pointer;

  appearance: none;

  @media $landscapeTablet {
    width: 169px;
    height: 56px;

    font-size: 12px;
    line-height: 16px;
  }
}

.button:disabled {
  color: rgba(0, 0, 0, 0.24);

  pointer-events: none;
}

.button:hover,
.button:active {
  background-color: #3d3d3d;
}

.buttonLabel {
  display: none;

  @media $landscapeTablet {
    display: inline;
  }
}

.buttonIcon {
  display: block;

  width: 24px;
  height: 16px;
  margin: 0 auto;

  fill: currentColor;

  @media $landscapeTablet {
    display: none;
  }
}

.buttonIcon svg {
  display: block;

  width: 100%;
  height: 100%;
}
