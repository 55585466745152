@import '../../../styles/vars.css';

.root {
  position: sticky;
  top: 75px;

  display: flex;
  flex-flow: column nowrap;

  width: 100%;
  padding: 10px;

  border: 1px solid #ebebeb;
  border-radius: 8px;
}

.body {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;

  height: 600px;
}

.link {
  display: block;
}

.image {
  display: block;

  width: 100%;
}
