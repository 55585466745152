/* stylelint-disable declaration-empty-line-before */
@import '../../../styles/vars.css';

.root {
  position: relative;
  z-index: auto;

  display: flex;
  flex-flow: column nowrap;

  width: 100%;
}

.wrapper {
  position: relative;
  z-index: 2;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 110px;
  height: 110px;
  margin: 0 auto;
}

.image {
  position: absolute;

  width: 100%;
  height: 100%;

  background-size: cover;

  border-radius: 2px;
}

.isInRichBlock .wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 160px;
  height: 160px;

  border-radius: 0;
  box-shadow: none;

  @media $landscapeTablet {
    width: 193px;
    height: 193px;
    margin-bottom: 4px;
  }
}

.isInRichBlock.is1to3 .wrapper {
  @media $landscapeTablet {
    width: calc(193rem / 20);
    height: calc(193rem / 20);
  }
}

.isInRichBlock.is1to4 .wrapper {
  @media $landscapeTablet {
    width: 140px;
    height: 140px;
  }
}

.isInEpisodeMaterial {
  justify-content: center;

  height: 100%;
}

.isInEpisodeMaterial .image {
  width: 185px;
  height: 185px;

  box-shadow: 0 3px 14px 0 rgba(0, 0, 0, 0.42);
}

.isInPodcastMaterial .wrapper {
  width: 160px;
  height: 160px;
}

.isInChronology .wrapper {
  width: 126px;
  height: 126px;
}

.isInRichBlock {
  position: static;

  justify-content: center;
}

.isInPodcastMaterial .image {
  box-shadow: 0 38px 60px rgba(0, 0, 0, 0.1), 0 25px 35px rgba(0, 0, 0, 0.07),
    0 14px 20px rgba(0, 0, 0, 0.05), 0 7.6px 9.75px rgba(0, 0, 0, 0.04),
    0 3px 5px rgba(0, 0, 0, 0.03), 0 1px 2px rgba(0, 0, 0, 0.02);
}

.isInRichBlock .image {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.06), 0 2px 6px rgba(0, 0, 0, 0.1),
    0 4px 12px rgba(0, 0, 0, 0.13), 0 8px 24px rgba(0, 0, 0, 0.16),
    0 16px 32px rgba(0, 0, 0, 0.2), 0 32px 64px rgba(0, 0, 0, 0.26);
}
