/* stylelint-disable declaration-empty-line-before */
@import '../../../../../styles/vars.css';

.root {
  position: relative;

  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;

  height: 40px;
  padding: 0 $gapSmall;
  overflow: hidden;

  white-space: nowrap;

  background-color: #fff;

  border-radius: 10px;

  @media $portraitTablet {
    height: 42px;
  }
}

.logo {
  display: inline-block;
  flex-shrink: 0;

  max-height: 16px;
  margin-right: 12px;

  vertical-align: bottom;

  @media $portraitTablet {
    max-height: 22px;
  }
}

.text {
  position: relative;

  display: inline-block;
  flex-flow: row nowrap;
  align-items: center;

  margin: 0;
  padding-top: 1px;
  padding-left: 13px;
  overflow: hidden;

  color: $brandColor;
  font-weight: 600;
  font-size: 12px;

  font-family: $secondaryFont;
  line-height: 13px;

  letter-spacing: 1px;
  text-transform: uppercase;
  text-overflow: ellipsis;

  @media $portraitTablet {
    font-size: 14px;
    line-height: 16px;
  }
}

.text::before {
  position: absolute;
  top: 0;
  left: 0;

  display: inline-block;

  height: 18px;

  border-left: 1px solid #d8d8d8;

  content: '';
}

.panel {
  position: relative;
  z-index: $zIndex-2;

  margin-top: 10px;

  @media $mobile {
    margin-top: $gapMedium;
  }
}

.panel:first-child {
  margin-top: 0;
  margin-bottom: 10px;

  @media $mobile {
    margin-top: 0;
    margin-bottom: $gapMedium;
  }
}
