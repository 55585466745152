/* stylelint-disable declaration-empty-line-before */
@import '../../../styles/vars.css';

.root {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.block {
  display: block;

  width: 100%;
  height: 100%;
}

.picture {
  position: absolute;
  top: 0;
  left: 50%;

  height: 250px;

  transform: translateX(-50%);
}

.picture img {
  height: 100%;
}
