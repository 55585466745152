/* stylelint-disable declaration-empty-line-before */
@import '../../../../styles/vars.css';

.root {
  position: relative;
  z-index: 999;
}

.root::after {
  @media $mobile {
    position: absolute;
    top: -15px;
    right: -15px;
    bottom: -15px;
    left: -15px;

    content: '';
  }
}

.panel {
  position: absolute;
  top: 0;
  right: 0;

  display: flex;
  flex-flow: row nowrap;
  align-items: center;

  width: 292px;
  height: 100%;
  overflow: hidden;

  background: #f7f7f7;

  border-radius: 50px;
  box-shadow: 0 6px 6px rgba(0, 0, 0, 0.24);

  @media $mobile {
    width: 44px;

    visibility: hidden;

    opacity: 0;

    transition-timing-function: ease;
    transition-duration: 350ms;
    transition-property: opacity, visibility, width;
  }
}

.root:hover .panel {
  @media $mobile {
    width: 330px;
    overflow: visible;

    visibility: visible;

    opacity: 1;

    transition-delay: 0s;
  }
}

.progress {
  width: 100%;
  min-width: 0;
  padding-left: 13px;

  @media $mobile {
    padding-left: 19px;
  }
}

.control {
  position: relative;
  z-index: $zIndex-1;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 40px;
  height: 40px;

  background: #b88b58;

  border-radius: 50%;
  box-shadow: 0 6px 6px 0 rgba(0, 0, 0, 0.24),
    inset 0 0 0 1px rgba(255, 255, 255, 0.05);

  fill: #fff;

  @media $mobile {
    width: 45px;
    height: 45px;
  }
}

.controls {
  display: flex;
  flex-direction: row;
  flex-shrink: 0;
  justify-content: center;

  padding-right: 47px;
}

.controls [data-tooltip] {
  position: relative;
}

.controls [data-tooltip]::before,
.controls [data-tooltip]::after {
  @media $mobile {
    position: absolute;
    left: 50%;
    z-index: $zIndex-10;

    transform: translateX(-50%);

    opacity: 0;
  }
}

.controls [data-tooltip]:hover::after,
.controls [data-tooltip]:hover::before {
  opacity: 1;
}

.controls [data-tooltip]::after {
  top: -38px;
  left: 50%;

  padding: 6px 15px;

  color: #fff;
  font-size: 13px;

  font-family: $secondaryFont;
  line-height: 16px;
  white-space: nowrap;

  text-align: center;

  background: rgba(38, 38, 38, 0.8);
  border-radius: 6px;

  @media $mobile {
    content: attr(data-tooltip);
  }
}

.controls [data-tooltip]::before {
  top: -10px;

  border-color: rgba(38, 38, 38, 0.8) transparent transparent transparent;
  border-style: solid;

  border-width: 4px 5px 0;

  @media $mobile {
    content: '';
  }
}

.title {
  display: block;

  padding-top: 1px;
  overflow: hidden;

  color: #494949;
  font-weight: 600;
  font-size: 13px;

  font-family: $secondaryFont;
  line-height: 17px;
  letter-spacing: 0.5px;

  white-space: nowrap;
  text-transform: uppercase;
  text-overflow: ellipsis;
}

.button {
  position: relative;

  display: flex;
  flex-direction: row;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;

  width: 37px;
  height: 37px;
  margin: 0;
  padding: 0;

  color: #b3b3b3;

  background: transparent;
  border-width: 0;

  cursor: pointer;

  appearance: none;

  fill: #b3b3b3;
}

.button:hover {
  color: #b78b5c;

  fill: #b78b5c;
}

.button:first-child::after {
  position: absolute;
  top: 11px;
  right: -1px;

  width: 1px;
  height: 15px;

  background-color: #d9d9d9;

  content: '';
}
