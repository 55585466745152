/* stylelint-disable declaration-empty-line-before */
@import '../../../../styles/vars.css';

.root {
  position: relative;

  padding: 0 10px 10px;

  background-color: #f2f2f2;

  border-radius: 8px;

  @media $landscapeTablet {
    padding: 0 0 45px;
  }
}

.switcher {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;

  margin-bottom: $gapSmall;

  @media $mobile {
    margin-bottom: $gapMedium;
  }
}

.divider {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  margin: 13px 0;

  font-weight: 700;

  cursor: default;

  user-select: none;
}

.divider::before,
.divider::after {
  display: inline-block;
  flex-grow: 1;

  max-width: 564px;
  height: 2px;

  border-radius: 10px;

  opacity: 0.6;

  content: '';
}

.divider::before {
  background-image: linear-gradient(-90deg, #848484 10%, #e6e6e6 85%);
}

.divider::after {
  background-image: linear-gradient(-90deg, #e6e6e6 10%, #848484 85%);
}

.divider span {
  padding: 0 12px;

  color: #9e9e9e;
  font-size: 10px;

  font-family: $secondaryFont;

  letter-spacing: 0.5px;
  text-transform: uppercase;
}

.divider span::before {
  display: inline-block;

  width: 8px;
  height: 9px;
  margin-top: -2px;
  margin-right: 5px;

  vertical-align: middle;

  background-image: url("data:image/svg+xml,%3Csvg width='8' height='9' viewBox='0 0 8 9' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill-rule='nonzero' fill='%239E9E9E'%3E%3Cpath d='M4 2.478L6.166 4.68c.4.406 1.07.43 1.497.05.428-.38.45-1.014.052-1.42L4.775.32c-.42-.426-1.13-.426-1.55 0L.286 3.31c-.4.405-.376 1.04.052 1.42.428.378 1.098.356 1.497-.05L4 2.48z'/%3E%3Cpath d='M3 1.055v6.89C3 8.528 3.448 9 4 9s1-.472 1-1.055v-6.89C5 .472 4.552 0 4 0S3 .472 3 1.055z'/%3E%3C/g%3E%3C/svg%3E");

  content: '';
}

.more {
  max-width: 650px;
  margin: 20px auto;

  text-align: center;

  @media $mobile {
    margin: $gapLarge auto;
  }
}

.update {
  position: sticky;
  top: 12px;
  z-index: 200;

  max-width: 650px;
  margin: 13px auto 15px;

  transition: padding 350ms ease;

  @media $mobile {
    top: 60px;
  }
}

.update[data-sticked='true'] {
  padding: 0 12px;

  @media $mobile {
    padding: 0 25px;
  }
}

.update button {
  width: 100%;
}

.notify {
  position: fixed;
  top: 80px;
  right: 0;
  left: 0;
  z-index: $zIndex-10;

  text-align: center;

  @media $mobile {
    top: 60px;
  }
}

.notifyButton {
  height: 42px;
  padding: 0 18px;
  padding-bottom: 2px;

  color: #fff;
  font-weight: 600;
  font-size: 16px;

  font-family: $secondaryFont;

  background: #b78b5c;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 21px;
  outline: none;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.12);

  cursor: pointer;
}

.notifyButton:hover {
  background-color: #9e784c;
}

.notifyButton:active {
  background-color: #856440;
}

.notifyButton::before {
  display: inline-block;

  width: 10px;
  height: 11px;
  margin-right: 4px;

  vertical-align: -1px;

  background-image: url("data:image/svg+xml,%3Csvg width='10' height='11' viewBox='0 0 10 11' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill-rule='nonzero' fill='%23FFF'%3E%3Cpath d='M8.236 5.545c.398.384 1.03.373 1.414-.025.384-.397.373-1.03-.025-1.414L5.66.28c-.387-.373-1-.373-1.388 0L.306 4.106C-.092 4.49-.104 5.123.28 5.52c.384.398 1.017.41 1.414.025L4.966 2.39l3.27 3.155z'/%3E%3Cpath d='M4 1.883v8.234c0 .488.448.883 1 .883s1-.395 1-.883V1.883C6 1.395 5.552 1 5 1s-1 .395-1 .883z'/%3E%3C/g%3E%3C/svg%3E");

  content: '';
}

.intro {
  margin: 0 0 $gapSmall;

  @media $mobile {
    margin: 0 0 $gapMedium;
  }
}
