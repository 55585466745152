@import '../../../../styles/vars.css';

.root {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 20px;

  @media $landscapeTablet {
    flex-direction: row;
    gap: 16px;
    margin-bottom: 14px;
  }
}

.tag {
  margin-bottom: 0;

  color: rgba(255, 255, 255, 0.5);
  font-weight: 600;

  font-size: 12px;
  line-height: 16px;

  letter-spacing: 1px;
  text-transform: uppercase;
}

.subscriptionToggle {
  display: flex;

  height: auto;

  font-weight: 600;
  font-size: 12px;

  font-family: 'Proxima Nova';
  font-style: normal;
  line-height: 16px;
  letter-spacing: 1px;

  text-align: center;
  text-transform: uppercase;

  background: rgba(255, 255, 255, 0.16);

  border-radius: 30px;

  @media $landscapeTablet {
    transform: translateY(-17.5%);
  }
}

.subscriptionToggle [name='subscription'] {
  display: none;
}

.subscriptionToggle input + label {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 84px;
  padding: 4px 8px;

  color: #fff;

  background: rgba(255, 255, 255, 0);
  border-radius: 30px;

  cursor: pointer;

  opacity: 0.4;

  user-select: none;
}

.subscriptionToggle input:checked + label {
  color: #fff;

  background: #545454;
  opacity: 1;
}
