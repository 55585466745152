@import '../../../styles/vars.css';

.root {
  position: relative;
  z-index: 10;

  display: flex;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  align-items: center;
  flex-flow: column nowrap;
  flex-grow: 1;

  padding: 25px 20px 10px;

  color: #fff;

  gap: 18px;
  overscroll-behavior: contain;

  @media $landscapeTablet {
    align-items: center;
    flex-flow: row nowrap;
    justify-content: space-between;

    padding: 40px 50px;

    gap: 60px;
  }
}

.main {
  position: relative;

  display: flex;
  align-items: center;
  flex-flow: column nowrap;

  width: 100%;
  max-width: 350px;
  height: 173px;
  padding: 0 15px;

  border-radius: 46px;
  background-color: #fff;

  gap: 2px;

  @media $landscapeTablet {
    flex-shrink: 0;

    width: 339px;
    height: 283px;
    padding: 15px 15px 20px;

    border-radius: 46px;
    background-color: #fff;
  }
}

.content {
  display: flex;
  flex-flow: column nowrap;

  width: 100%;
  max-width: 350px;

  background-image: url('./assets/PopoverContentMobile.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100% 100%;

  font-family: $primaryFont;
  font-size: 16px;
  line-height: 22px;

  gap: 16px;
  aspect-ratio: 350 / 315;

  @media $landscapeTablet {
    width: 100%;
    max-width: 764px;

    background-image: url('./assets/PopoverContentDesktop.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100% 100%;

    aspect-ratio: 764 / 357;
  }
}

.content p {
  margin: 0;
}

.content span {
  letter-spacing: 0.8px;
  text-transform: uppercase;

  font-family: $secondaryFont;
  font-size: 20px;
  font-weight: 700;
  line-height: 33px;
}

.content i {
  @media $landscapeTabletMax {
    display: block;

    margin-bottom: 20px;
  }

  @media (max-width: 359px) {
    margin-bottom: 10px;
  }
}

.button {
  display: flex;
  align-items: center;
  flex-flow: row nowrap;
  justify-content: center;

  height: 60px;

  letter-spacing: 1px;
  text-transform: uppercase;

  color: #000;
  border-width: 0;
  border-radius: 30px;
  background-color: #fff;

  font-size: 14px;
  font-weight: 600;
  line-height: 16px;
}

.icon {
  margin-left: 1px;

  transform: translateY(-4px);
}

.button:hover {
  opacity: 0.8;
}

.header {
  position: absolute;
  z-index: 20;
  top: 26px;
  left: 26px;

  display: flex;
  flex-flow: row nowrap;

  gap: 2px;

  @media $landscapeTablet {
    position: static;
  }
}

.variant {
  display: flex;
  flex-flow: row nowrap;
  flex-grow: 1;

  height: 40px;

  border-radius: 82px;
  background-color: rgba(255, 255, 255, 0.2);
}

.variantButton {
  width: 100%;
  height: 40px;
  padding: 2px 0 0;

  cursor: pointer;
  text-align: center;
  letter-spacing: 1px;
  text-transform: uppercase;

  color: rgba(255, 255, 255, 0.8);
  border-width: 0;
  border-radius: 40px;
  background-color: transparent;

  font-size: 8px;
  font-weight: 600;
  line-height: 136%;
}

.variantButton.isActive {
  cursor: default;

  color: #000;
  background-color: #fff;
}

.currency {
  display: flex;
  flex-flow: row nowrap;
  flex-shrink: 0;

  width: 82px;
  height: 41px;

  border-radius: 82px;
}

.currencyButton {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 41px;
  height: 41px;
  padding-top: 3px;

  cursor: pointer;
  letter-spacing: 1px;
  text-transform: uppercase;

  color: #000;
  border-width: 0;
  border-radius: 41px;
  background-color: rgba(0, 0, 0, 0.1);

  font-size: 12px;
  font-weight: 600;
  line-height: 136%;
}

.currencyButton.isActive {
  cursor: default;

  color: #fff;
  background-color: #000;
}

.variantsList {
  display: flex;
  flex-flow: column nowrap;

  width: 100%;

  gap: 2px;

  @media $landscapeTablet {
    flex-flow: row nowrap;
  }
}

.variantsItem {
  position: relative;

  width: 100%;
}

.variantPrice,
.eur,
.usd {
  height: 97px;
  margin: 0 11px;

  background-repeat: no-repeat;
  background-position: center right;
  background-size: 111px 49px;

  @media $landscapeTablet {
    height: 137px;

    background-position: center;
    background-size: 196px 87px;
  }
}

.usd {
  background-image: url("data:image/svg+xml,%3Csvg width='196' height='87' viewBox='0 0 196 87' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23a)'%3E%3Cpath d='M19.91 50.29V45.1c-8.364-.577-15.191-3.27-18.94-6.827l4.999-7.019c4.134 3.077 10.576 5.865 17.788 5.865 5.96 0 9.23-1.442 9.23-3.365 0-2.98-5-3.461-11.057-4.134-8.846-.866-20.192-1.923-20.192-12.307 0-5.865 6.25-11.25 18.173-11.827V.197h6.153v5.48c7.98.674 13.846 3.174 17.018 6.058l-5 6.923c-3.75-2.885-8.653-5.192-16.345-5.192-5.96 0-8.557 1.154-8.557 2.788 0 2.788 5 3.27 10.961 4.038 9.038 1.058 20.576 2.404 20.576 12.211 0 7.404-6.442 11.923-18.653 12.596v5.192h-6.153Z' fill='%23000'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='a'%3E%3Cpath fill='%23fff' d='M0 0h196v87H0z'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E");
}

.eur {
  background-image: url("data:image/svg+xml,%3Csvg width='196' height='87' viewBox='0 0 196 87' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23a)'%3E%3Cpath d='M27.402 33.789c6.403 0 10.614-2.25 12.98-6l8.538 4.558c-3.52 5.134-10.846 9.345-21.864 9.345-11.134 0-21.115-5.076-23.768-13.73H.115v-4.5h2.25a15.24 15.24 0 0 1-.116-1.672c0-.693.058-1.27.116-1.904H.115v-4.442h3.173C5.999 6.848 15.979 1.887 27.056 1.887c11.076 0 18.46 4.153 21.921 9.634l-8.25 4.673c-2.71-4.096-6.806-6.404-13.325-6.404-5.885 0-10.788 1.962-13.211 5.654h19.326v4.442H12.402c-.057.634-.115 1.211-.115 1.904 0 .576.058 1.153.115 1.672h21.115v4.5H14.075c2.481 3.692 7.385 5.827 13.327 5.827Z' fill='%23000'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='a'%3E%3Cpath fill='%23fff' d='M0 0h196v87H0z'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E");
}

.variantPrice {
  background-image: url("data:image/svg+xml,%3Csvg width='196' height='87' viewBox='0 0 196 87' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23a)' fill='%23000'%3E%3Cpath d='M156.249 86.405c-26.944 0-39.362-20.384-39.362-40.416 0-20.15 12.418-40.3 39.362-40.3 26.827 0 39.245 20.15 39.245 40.3 0 20.032-12.418 40.416-39.245 40.416Zm-18.861-40.416c0 13.472 5.74 24.367 18.861 24.367 13.003 0 18.744-10.895 18.744-24.367 0-13.472-5.623-24.25-18.744-24.25-13.121 0-18.861 10.777-18.861 24.25ZM79.33 85V30.29l-17.105 14.41-10.543-12.77 29.99-25.07h17.572V85H79.33Z'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='a'%3E%3Cpath fill='%23fff' d='M0 0h196v87H0z'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E");
}

.variantsItemText {
  visibility: hidden;
}

.variantsItemButton {
  width: 100%;
}

.variantsItemButton a {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 70px;

  transition: background-color 300ms ease;
  letter-spacing: 1px;
  text-transform: uppercase;

  color: #fff;
  border-radius: 35px;
  background-color: #000;
  background-image: url("data:image/svg+xml,%3Csvg width='126' height='16' viewBox='0 0 126 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8.77 15.25c-3.323 0-6.355-1.247-7.81-2.534l1.725-2.347c1.37 1.163 3.696 2.118 6.126 2.118 2.306 0 3.406-.582 3.406-1.454 0-1.08-1.433-1.287-3.925-1.537-3.344-.332-6.854-.913-6.854-4.257 0-2.638 2.7-4.32 7.02-4.32 2.762 0 5.338.79 7.061 2.264l-1.703 2.284C12.487 4.43 10.473 3.64 8.188 3.64c-1.973 0-3.011.457-3.011 1.246 0 .997 1.267 1.225 3.966 1.557 3.323.416 6.771.997 6.771 4.196 0 2.637-2.617 4.61-7.144 4.61Zm17.898 0c-4.797 0-7.767-2.534-7.767-6.626V1.148h3.53v7.435c0 2.263 1.62 3.634 4.237 3.634s4.237-1.37 4.237-3.634V1.148h3.53v7.476c0 4.07-2.97 6.625-7.767 6.625Zm11.61-.25V1.148H48.37c3.178 0 5.047 1.765 5.047 4.527 0 2.741-1.89 4.528-5.047 4.528h-6.563V15h-3.53Zm3.53-7.643h6.085c1.267 0 1.973-.581 1.973-1.682 0-1.06-.706-1.661-1.973-1.661h-6.085v3.343ZM56.275 15V1.148H66.37c3.177 0 5.046 1.765 5.046 4.527 0 2.741-1.89 4.528-5.046 4.528h-6.563V15h-3.53Zm3.53-7.643h6.086c1.267 0 1.973-.581 1.973-1.682 0-1.06-.706-1.661-1.973-1.661h-6.085v3.343Zm22.567 7.892c-5.109 0-8.847-2.679-8.847-7.165 0-4.486 3.738-7.165 8.847-7.165 5.11 0 8.848 2.68 8.848 7.165 0 4.486-3.739 7.165-8.848 7.165ZM77.16 8.084c0 2.638 2.057 4.32 5.213 4.32 3.157 0 5.213-1.682 5.213-4.32 0-2.617-2.056-4.32-5.213-4.32-3.156 0-5.213 1.703-5.213 4.32ZM94.34 15V1.148h10.031c3.219 0 5.109 1.744 5.109 4.548 0 2.43-1.371 3.759-3.634 4.257L109.687 15h-4.299l-3.406-4.797H97.87V15h-3.531Zm3.53-7.622h6.086c1.267 0 1.993-.602 1.993-1.703 0-1.06-.726-1.661-1.993-1.661H97.87v3.364ZM116.986 15V4.159h-5.379V1.148h14.289v3.011h-5.358V15h-3.552Z' fill='%23fff'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 86px 24px;

  font-family: $secondaryFont;
  font-size: 20px;
  font-weight: 700;

  @media $landscapeTablet {
    background-size: 126px 16px;
  }
}

.variantsItemButton span {
  visibility: hidden;

  opacity: 0;
}

.variantsItemButton a::after {
  position: absolute;

  content: '';

  inset: 0;
}

.variantsItemButton a:hover {
  background-color: rgba(0, 0, 0, 0.8);
}
