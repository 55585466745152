/* stylelint-disable declaration-empty-line-before */
/* stylelint-disable declaration-colon-newline-after */

@import '../../../../styles/vars.css';

.root {
  position: relative;
  z-index: 1;

  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: hidden;

  background: #fff;

  border-radius: 8px;
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.1);

  transition: opacity 0.2s;

  -webkit-tap-highlight-color: transparent;
}

.tag {
  margin-bottom: 9px;

  color: $brandColor;

  line-height: 12px;
}

.content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  padding: $gapSmall;
}

.title {
  flex-grow: 1;

  margin-bottom: 18px;
}

.meta {
  color: #757575;

  line-height: 14px;

  fill: $brandColor;
}

.isPicture {
  color: #fff;

  background-color: #1a1a1a;
}

.isPicture .meta {
  color: rgba(255, 255, 255, 0.6);
}

.content {
  padding: 16px 12px 13px;
}

.video {
  position: relative;
  z-index: $zIndex-2;
}

.picture:last-child {
  padding-bottom: 12px;
}

.cc {
  position: relative;
  z-index: $zIndex-2;

  padding: 10px 12px 12px;

  font-size: 14px;

  font-family: $secondaryFont;
  line-height: 18px;
}

.cc p {
  margin: 0;
}

.cc a {
  color: inherit;

  box-shadow: inset 0 -1px;
}

.control {
  margin: 0;
  padding: 0;

  color: #757575;

  background-color: transparent;
  border-width: 0;

  appearance: none;
}

.captionExpanded {
  display: none;
}

.expanded .captionShort {
  display: none;
}

.expanded .captionExpanded {
  display: block;
}

.credit {
  padding-top: 1px;

  color: #666;

  font-size: 12px;
  line-height: 15px;
}

.isPicture .credit {
  color: rgba(255, 255, 255, 0.5);
}
