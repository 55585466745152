/* stylelint-disable declaration-empty-line-before */
@import '../../../../styles/vars.css';

.root {
  position: relative;

  @media $landscapeTablet {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
  }
}

.container {
  position: relative;
  z-index: 10;

  max-width: 1030px;
  margin: 25px 10px;
  padding: 22px 0 17px;

  background-color: #fff;

  border-radius: 10px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2);

  @media $landscapeTablet {
    width: 100%;
    margin: 35px 0;
    padding: 45px 0 24px;
  }
}

.head {
  display: flex;
  flex-flow: column nowrap;

  margin: 0 auto 18px;
  padding: 0 20px;

  @media $landscapeTablet {
    flex-flow: row nowrap;

    margin: 0 auto 25px;
  }
}

.materialHeader {
  max-width: 840px;
}

.body {
  padding: 0 20px 15px;

  @media $mobile {
    padding: 0 15px 15px;
  }
}

.center .head {
  justify-content: center;
}

.center .materialHeader {
  @media $mobile {
    align-items: center;

    text-align: center;
  }
}

.banner,
.image {
  display: block;

  width: 100%;
}

.banner.top::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  content: '';
}

.banner.top .image {
  margin-bottom: -25px;

  @media $mobile {
    margin-bottom: -35px;
  }
}

.banner.bottom .image {
  margin-top: -13px;

  @media $mobile {
    margin-top: -20px;
  }
}

.image img {
  width: 100%;
  max-width: none;

  vertical-align: bottom;
}

.image.desktop {
  display: none;

  @media $mobile {
    display: block;
  }
}

.image.mobile {
  @media $mobile {
    display: none;
  }
}

.image.responsive img {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
}

.banner.bottom {
  position: relative;
  z-index: 10;
}

.footer {
  position: relative;
  z-index: 10;

  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: flex-end;

  width: 100%;
  height: 50px;

  font-size: 17px;

  font-family: $secondaryFont;
  line-height: 47px;

  background-color: #252525;
}

.footer a {
  color: rgba(255, 255, 255, 0.5);
}

.footer a:hover {
  color: #fff;
}

.branding .footer {
  display: none;
}

.branding .top img {
  border-radius: 10px 10px 0 0;
}

.branding .bottom img {
  border-radius: 0 0 10px 10px;
}
