/* stylelint-disable declaration-empty-line-before */
@import '../../../../styles/vars.css';

.root {
  display: flex;
  flex-flow: column nowrap;
  flex-grow: 1;

  width: 100%;

  min-height: 330px;
  padding: 36px 12px 12px;

  background-color: #181818;

  border-radius: 8px;
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.1);

  @media $landscapeTablet {
    align-items: center;
    justify-content: center;

    padding: 45px 15px;
  }
}

.body {
  width: 100%;
  max-width: 456px;
  margin: 0 auto;

  @media $landscapeTablet {
    text-align: center;
  }
}

.content {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;

  max-width: 680px;
  margin: 0 auto;

  color: #fff;

  @media $landscapeTablet {
    text-align: center;
  }
}

.text {
  font-size: 18px;
  font-family: $primaryFont;
  line-height: 24px;

  @media $landscapeTablet {
    font-size: 19px;
    line-height: 23px;
  }
}

.text p {
  max-width: 500px;
  margin: 0;
}

.is1to3 .text,
.is1to4 .text {
  @media $landscapeTablet {
    font-size: 17px;
    line-height: 21px;
  }
}

.is1to2 .title {
  @media $landscapeTablet {
    font-size: 26px;
    line-height: 32px;
  }
}

.is1to3 .title {
  @media $landscapeTablet {
    font-size: 20px;
    line-height: 27px;
  }
}

.is1to4 .title {
  @media $landscapeTablet {
    font-size: 18px;
    line-height: 23px;
  }
}

.content a {
  color: inherit;

  box-shadow: inset 0 -1px 0 #b78b5c;
}

.content a:hover {
  color: $brandColor;
}

.title {
  width: 100%;
  margin-bottom: 2px;

  font-weight: 700;
  font-size: 24px;

  font-family: $secondaryFont;
  line-height: 27px;

  @media $landscapeTablet {
    margin-bottom: 4px;
  }
}

.form {
  position: relative;

  display: flex;
  flex-flow: row nowrap;

  justify-items: center;

  width: 100%;
  max-width: 450px;
}

.field {
  width: 100%;

  text-align: left;
}

.field label {
  position: absolute;
  z-index: 1;

  visibility: hidden;

  opacity: 0;
}

.message {
  display: block;
  min-height: 32px;

  margin-top: 8px;
  padding: 0 12px;

  color: #f2614e;
  font-size: 12px;

  font-family: $secondaryFont;
  line-height: 13px;

  text-align: left;
}

.message a {
  box-shadow: inset 0 -1px;
}

.input {
  display: block;

  width: 100%;
  height: 42px;
  padding: 0 12px 0 0;

  color: #000;
  font-weight: 500;
  font-size: 18px;

  font-family: $secondaryFont;
  line-height: 42px;
  text-indent: 12px;

  border-width: 0;
  border-radius: 8px 0 0 8px;
  outline: none;

  transition: border-color 0.1s ease-in;

  appearance: none;

  &:focus {
    border-color: rgba(0, 0, 0, 0.5);
  }
}

.input::placeholder {
  color: #b2b2b2;

  opacity: 1;
}

.input:focus {
  border-color: rgba(0, 0, 0, 0.5);
}

.button {
  flex-shrink: 0;

  width: 42px;
  height: 42px;
  padding: 1px 1px 0 0;

  line-height: 42px;

  background-color: $brandColor;

  border-width: 0;
  border-radius: 0 8px 8px 0;
  box-shadow: none;

  cursor: pointer;

  appearance: none;
}

.success {
  width: 100%;
  min-height: 96px;

  color: #808080;
  font-size: 18px;

  font-family: $secondaryFont;
  line-height: 21px;

  @media $landscapeTablet {
    min-height: 42px;
  }
}

.description {
  padding-top: 8px;
}

.description p {
  margin: 0 auto;

  color: rgba(255, 255, 255, 0.4);
  font-size: 12px;

  font-family: $secondaryFont;
  line-height: 16px;

  @media $landscapeTablet {
    max-width: 290px;

    text-align: center;
  }
}

.description a {
  box-shadow: inset 0 -1px;
}

.is1to1 {
  @media $landscapeTablet {
    min-height: 410px;
    padding-top: 45px;
    padding-bottom: 45px;
  }
}

.is1to1 .body {
  @media $landscapeTablet {
    width: 100%;
    max-width: 910px;
  }
}

.is1to1 .content {
  @media $landscapeTablet {
    position: relative;

    font-size: 20px;
    line-height: 25px;
  }
}

.is1to1 .title {
  @media $landscapeTablet {
    font-size: calc(35rem / $fontSize);
    line-height: calc(40rem / $fontSize);
  }
}

.is1to1 .form {
  @media $landscapeTablet {
    margin: 0 auto;
  }
}

.is1to2 .content p {
  max-width: 440px;
}

.is1to2 .subscribe {
  @media $portraitTablet {
    margin-top: 18px;
  }
}

.is1to3 .content {
  @media $landscapeTablet {
    max-width: 295px;
    margin: 0 auto;
  }
}

.is1to3 .subscribe,
.is1to4 .description p {
  @media $landscapeTablet {
    max-width: 290px;
  }
}

.subscribe {
  position: relative;

  width: 100%;
  margin: 21px auto 0;

  @media $landscapeTablet {
    margin-top: 28px;
  }
}

.invalid .input {
  border-color: #f2614e;
  box-shadow: inset 0 0 0 2px #f2614e;
}
